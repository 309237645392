import Vue from "vue";
import VueRouter from "vue-router";
import goTo from "vuetify/es5/services/goto";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    meta: {
      title: "Cody Squadroni",
    },
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/LDTE5870",
    meta: {
      title: "Cody Squadroni - LDTE 5870",
    },
    component: () => import("../views/LDTE5870/Home.vue"),
  },
  {
    path: "/LDTE5870-2",
    meta: {
      title: "Cody Squadroni - LDTE 5870",
    },
    component: () => import("../views/LDTE5870-2/Home.vue"),
  },
  {
    path: "/LDTE5870-3",
    meta: {
      title: "Cody Squadroni - LDTE 5870",
    },
    component: () => import("../views/LDTE5870-3/Home.vue"),
  },
  {
    path: "/LDTE5210",
    meta: {
      title: "Cody Squadroni - LDTE 5210",
    },
    component: () => import("../views/LDTE5210/Home.vue"),
  },
  {
    path: "/LDTE5210/design-statement",
    meta: {
      title: "Cody Squadroni - LDTE 5210 Design Statement",
    },
    component: () => import("../views/LDTE5210/DesignStatement.vue"),
  },
  {
    path: "/LDTE5210/mp-part1",
    meta: {
      title: "Cody Squadroni - LDTE 5210 Major Project Part 1",
    },
    component: () => import("../views/LDTE5210/MPP1.vue"),
  },
  {
    path: "/LDTE5210/microlearning",
    meta: {
      title: "Cody Squadroni - LDTE 5210 Microlearning",
    },
    component: () => import("../views/LDTE5210/Microlearning.vue"),
  },
  {
    path: "/LDTE5870-2/individual_reflection",
    meta: {
      title: "Cody Squadroni - LDTE 5870 Individual Reflection",
    },
    component: () => import("../views/LDTE5870-2/IndividualReflection.vue"),
  },
  {
    path: "/LDTE5870-3/proposal",
    meta: {
      title: "Cody Squadroni - LDTE 5870 Grant Proposal",
    },
    component: () => import("../views/LDTE5870-3/Proposal.vue"),
  },
  {
    path: "/LDTE5870-3/proposalb",
    meta: {
      title: "Cody Squadroni - LDTE 5870 Grant Proposal",
    },
    component: () => import("../views/LDTE5870-3/Proposalb.vue"),
  },
  {
    path: "/LDTE5870-3/reflection",
    meta: {
      title: "Cody Squadroni - LDTE 5870 Reflection",
    },
    component: () => import("../views/LDTE5870-3/Reflection.vue"),
  },
  {
    path: "/LDTE5870-3/reviews",
    meta: {
      title: "Cody Squadroni - LDTE 5870 Grant Reviews",
    },
    component: () => import("../views/LDTE5870-3/Reviews.vue"),
  },
  {
    path: "/LDTE5870-3/reviewsb",
    meta: {
      title: "Cody Squadroni - LDTE 5870 Grant Reviews",
    },
    component: () => import("../views/LDTE5870-3/Reviewsb.vue"),
  },
  {
    path: "/LDTE5870/research_agenda",
    meta: {
      title: "Cody Squadroni - Research Agenda",
    },
    component: () => import("../views/LDTE5870/ResearchAgenda.vue"),
  },
  {
    path: "/LDTE5870/research_agenda_final",
    meta: {
      title: "Cody Squadroni - Research Agenda",
    },
    component: () => import("../views/LDTE5870/ResearchAgendaFinal.vue"),
  },
  {
    path: "/LDTE5000",
    meta: {
      title: "Cody Squadroni - LDTE 5000",
    },
    component: () => import("../views/LDTE5000/Home.vue"),
  },
  {
    path: "/LDTE5000/invention",
    meta: {
      title: "Cody Squadroni - LDTE 5000 Pitch an Idea",
    },
    component: () => import("../views/LDTE5000/Invention.vue"),
  },
  {
    path: "/LDTE5020/journey",
    meta: {
      title: "Cody Squadroni - LDTE 5020 Journey Map",
    },
    component: () => import("../views/LDTE5020/Journey.vue"),
  },
  {
    path: "/LDTE5020",
    meta: {
      title: "Cody Squadroni - LDTE 5020",
    },
    component: () => import("../views/LDTE5020/Home.vue"),
  },
  {
    path: "/LDTE5000/self-portrait",
    meta: {
      title: "Cody Squadroni - LDTE 5000 Module 1 Assignment",
    },
    component: () => import("../views/LDTE5000/SelfPortrait.vue"),
  },
  {
    path: "/LDTE5020/teaching-with-technology",
    meta: {
      title: "Cody Squadroni - LDTE 5020 Teaching With Technology",
    },
    component: () => import("../views/LDTE5020/TeachingWithTechnology.vue"),
  },
  {
    path: "/LDTE5020/teaching-with-technology-revised",
    meta: {
      title: "Cody Squadroni - LDTE 5020 Teaching With Technology Revised",
    },
    component: () =>
      import("../views/LDTE5020/TeachingWithTechnologyRevised.vue"),
  },
  {
    path: "/LDTE5020/creating",
    meta: {
      title: "Cody Squadroni - LDTE 5020 Creating",
    },
    component: () => import("../views/LDTE5020/Creating.vue"),
  },
  {
    path: "/LDTE5020/communicating",
    meta: {
      title: "Cody Squadroni - LDTE 5020 Communicating",
    },
    component: () => import("../views/LDTE5020/Communicating.vue"),
  },
  {
    path: "/LDTE5020/collaborating",
    meta: {
      title: "Cody Squadroni - LDTE 5020 Collaborating",
    },
    component: () => import("../views/LDTE5020/Collaborating.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
